<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-uppercase">{{$t('title.order')}}#{{detailLoad.order_id}} </span>
                                    <span><small><ol-status :status="detailLoad.load_status"></ol-status></small></span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.order')}}: </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.importerNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.invoice_number_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.invoiceDateImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.invoice_date_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.brand')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.brand ? detailLoad.brand.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.model')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.model ? detailLoad.model.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.status')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ol-status :status="detailLoad.load_status"></ol-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.transportRequestAt')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_at">
                                            {{detailLoad.client_tr_at ? $global.utcDateToLocalDate(detailLoad.client_tr_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.transportRequestAcceptedAt')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_accept_at">
                                            {{detailLoad.client_tr_accept_at ? $global.utcDateToLocalDate(detailLoad.client_tr_accept_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.dealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.dealer">
                                            {{detailLoad.dealer ? detailLoad.dealer.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.dealerLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.dealer_location">
                                            <span v-if="detailLoad.dealer_location.street">{{detailLoad.dealer_location.street}}, </span>
                                            <span v-if="detailLoad.dealer_location.street_no">{{detailLoad.dealer_location.street_no}}, </span>
                                            <span v-if="detailLoad.dealer_location.city">{{detailLoad.dealer_location.city}}, </span>
                                            <span v-if="detailLoad.dealer_location.zip">{{detailLoad.dealer_location.zip}}, </span>
                                            <span v-if="detailLoad.dealer_location.country">{{detailLoad.dealer_location.country}}</span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                    <div class="mt-3" v-if="detailLoad.inbound_load">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.inboundLoad')}}: #{{detailLoad.inbound_load.load_number}} </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.supplier_carrier">
                                            {{detailLoad.inbound_load.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCompound')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.supplier_compound">
                                            {{detailLoad.inbound_load.supplier_compound.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.route">
                                            <span v-if="detailLoad.inbound_load.route.from_location.street">{{detailLoad.inbound_load.route.from_location.street}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.from_location.street_no">{{detailLoad.inbound_load.route.from_location.street_no}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.from_location.city">{{detailLoad.inbound_load.route.from_location.city}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.from_location.zip">{{detailLoad.inbound_load.route.from_location.zip}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.from_location.country">{{detailLoad.inbound_load.route.from_location.country}}</span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.toLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.route">
                                            <span v-if="detailLoad.inbound_load.route.to_location.street">{{detailLoad.inbound_load.route.to_location.street}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.to_location.street_no">{{detailLoad.inbound_load.route.to_location.street_no}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.to_location.city">{{detailLoad.inbound_load.route.to_location.city}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.to_location.zip">{{detailLoad.inbound_load.route.to_location.zip}}, </span>
                                            <span v-if="detailLoad.inbound_load.route.to_location.country">{{detailLoad.inbound_load.route.to_location.country}}</span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.drive">
                                            {{detailLoad.inbound_load.drive ? detailLoad.inbound_load.drive.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.inbound_load.transport_vehicle">
                                            <span v-if="detailLoad.inbound_load.transport_vehicle.truck_plate_number"> {{ detailLoad.inbound_load.transport_vehicle.truck_plate_number }} </span>
                                            <span v-if="detailLoad.inbound_load.transport_vehicle.trailer_plate_number"> | {{ detailLoad.inbound_load.transport_vehicle.trailer_plate_number }} </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.status')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ibl-status :status="detailLoad.inbound_load.status"></ibl-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-if="detailLoad.additional_services">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.additionalServices')}}</span></h4>
                            <hr>
                            <div class="clearfix">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{$t('column.additionalService')}}</th>
                                        <th>{{$t('column.description')}}</th>
                                        <th>{{$t('column.compoundNote')}}</th>
                                        <th>ETR</th>
                                        <th>{{$t('column.status')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(service,index) in detailLoad.additional_services">
                                        <td>{{index + 1}}</td>
                                        <td>{{service.additional_service.title}}</td>
                                        <td>{{service.description}}</td>
                                        <td>{{service.compound_notes}}</td>
                                        <td>{{service.estimated_time_repair ? $global.dateFormat(service.estimated_time_repair): ''}}</td>
                                        <td>
                                                <span v-if="service.is_rejected">
                                                    {{ $t('title.rejected') }}
                                                </span>
                                            <span v-else>
                                                    {{(!(!service.marked_done_at)) ? $t('title.done') : $t('title.pending') }}
                                                </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-if="detailLoad.outbound_load">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.outboundLoad')}}: #{{detailLoad.outbound_load.load_number}} </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.supplier_carrier">
                                            {{detailLoad.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCompound')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.supplier_compound">
                                            {{detailLoad.supplier_compound.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.route">
                                            <span v-if="detailLoad.outbound_load.route.from_location.street_no">{{detailLoad.outbound_load.route.from_location.street_no}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.from_location.street">{{detailLoad.outbound_load.route.from_location.street}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.from_location.city">{{detailLoad.outbound_load.route.from_location.city}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.from_location.zip">{{detailLoad.outbound_load.route.from_location.zip}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.from_location.country">{{detailLoad.outbound_load.route.from_location.country}}</span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.toLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.route">
                                            <span v-if="detailLoad.outbound_load.route.to_location.street_no">{{detailLoad.outbound_load.route.to_location.street_no}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.to_location.street">{{detailLoad.outbound_load.route.to_location.street}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.to_location.city">{{detailLoad.outbound_load.route.to_location.city}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.to_location.zip">{{detailLoad.outbound_load.route.to_location.zip}}, </span>
                                            <span v-if="detailLoad.outbound_load.route.to_location.country">{{detailLoad.outbound_load.route.to_location.country}}</span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.dealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.dealer">
                                            {{detailLoad.outbound_load.dealer.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.drive">
                                            {{detailLoad.outbound_load.drive ? detailLoad.outbound_load.drive.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.transport_vehicle">
                                            <span v-if="detailLoad.outbound_load.transport_vehicle.truck_plate_number"> {{ detailLoad.outbound_load.transport_vehicle.truck_plate_number }} </span>
                                            <span v-if="detailLoad.outbound_load.transport_vehicle.trailer_plate_number"> | {{ detailLoad.outbound_load.transport_vehicle.trailer_plate_number }} </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.status')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ibl-status :status="detailLoad.outbound_load.status"></ibl-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
    </div><!--/div-->
</template>
<script>
import {serverError} from "../../../../util/Notify";
import {request} from "../../../../util/Request";

export default {
    props: ['id', 'handleCloseOperation'],
    data() {
        return {
            detailLoad: null,
            items: []
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
            this.getDetail(this.$route.query.oToken)
        } else {
            serverError(this.$notification)
        }
    },
    methods: {
        async getDetail(id) {
            await request({
                url: `/orders/dealer/detail/${id}`,
            })
                .then((response) => {
                    const {data} = response
                    this.detailLoad = data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleCancelClick() {
            this.handleCloseOperation()
        }
    }
}
</script>
